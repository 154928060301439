import React from "react";
import { FooterWrapper } from "./Footer.styled";

export const Footer = () => {
  return (
    <FooterWrapper>
      <p>©2022 Sanvis Dining Solutions, LLC v1.0.1018</p>
    </FooterWrapper>
  );
};
