import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Homepage } from "./pages/Home/Homepage";
import { LoginPage } from "./pages/Login/LoginPage";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { SingleProduct } from "./pages/SingleProduct/SingleProduct";
import { useState } from "react";
import { DelPartner } from "./pages/DeliveryType/DeliveryType";
import { OrderPage } from "./pages/Order/OrderPage";
import { selectUser } from "./redux/user/userSlice";
import { useSelector } from "react-redux";
import store from "./redux/store";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRouter";

export default function App() {
  const [showHeader, setShowHeader] = useState(true);
  const [showBugIcon, setShowBugIcon] = useState(false);
  const [orderView, setOrderView] = useState(null);
  const location = useLocation();
  const userLogin = useSelector((store) => store);
  const pathName = location.state?.from || "/del";
  return (
    <div className="App">
      <Routes>
        <Route
          element={<Layout showBugIcon={showBugIcon} showHeader={showHeader}  setOrderView={setOrderView}
          orderView={orderView}/>}
        >
          <Route
            path="/del"
            element={
              <PrivateRoute>
                <DelPartner />
              </PrivateRoute>
            }
          />
          <Route
            path="login"
            element={
              <ProtectedRoute>
                <LoginPage setShowHeader={setShowHeader} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Homepage
                  setShowBugIcon={setShowBugIcon}
                  setShowHeader={setShowHeader}
                  setOrderView={setOrderView}
                  orderView={orderView}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="product"
            element={
              <SingleProduct
                showHeader={showHeader}
                setShowHeader={setShowHeader}
              />
            }
          />

          <Route
            path="order"
            element={
              <OrderPage
                showHeader={showHeader}
                setShowHeader={setShowHeader}
              />
            }
          />
        </Route>
      </Routes>
    </div>
  );
}
