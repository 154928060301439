import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
  :active,
  :focus {
    outline: 0;
  }
  a:active,
  a:focus {
    outline: 0;
  }
  aside,
  footer,
  header,
  nav {
    display: block;
  }
  body,
  html,#root,.App {
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
  }
  button,
  input,
  textarea {
    font-family: 'Inter', sans-serif;
  }
  input::-ms-clear {
    display: none;
  }
  button {
    cursor: pointer;
  }
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  a,
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  ul li {
    list-style: none;
  }
  img {
    vertical-align: top;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
    box-sizing: border-box;
  }

  :root {
  }

  .layout{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  main {
    flex: auto;
}
.product_container{
  /* position: relative; */
  height: 100%;
  .go_prd{
    position: relative;
    top: 18px;
    left: 17px;
    cursor: pointer;
    z-index: 9;
    width: 24px;
  }
}
.products_wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.order_btn_wrapper{
  margin-top: auto;
  margin-bottom: 14px;
  padding: 0 12px
;
}
.order_btn{
  display: block;
  max-width: 338px;
  width: 100%;
  margin: 0 auto;
  background: #4D7443;
  border-radius: 140px;
  text-align: center;
  padding: 15px 0px;
 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
  .go_prd{
    position: absolute;
    top: 25px;
    left: 25px;
    cursor: pointer;
  }
  .date_del{
    padding: 20px;
    padding-bottom: 5px;
    color: #4d7443;
    font-weight: 600;
    text-align: center;
  }
`;
