const getLocalStorage = () => {
  let user = sessionStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  } else {
    return { isLoggedIn: false, msg: "" };
  }
};

export function selectUser(state) {
  return state;
}

export function errorMsg(state) {
  return state.msg;
}

export const initialUser = getLocalStorage();

export function userReducer(state = {}, action) {
  if (action.type === "login_success_resident") {
    return {
      ...state,
      isLoggedIn: true,
      msg: null,
      userResident: action.payload.data,
      userVirtualDinning: null,
    };
  }
  if (action.type === "login_success_virtualDinning") {
    return {
      ...state,
      isLoggedIn: true,
      msg: null,
      userVirtualDinning: action.payload.data,
    };
  }
  if (action.type === "login_fail_resident") {
    return {
      ...state,
      isLoggedIn: false,
      userResident: action.payload.data,
      userVirtualDinning: null,
    };
  }
  if (action.type === "menu_items") {
    return {
      ...state,
      menuItems: action.payload.data
    };
  }
  if (action.type === "menu_items_filter") {
    return {
      ...state,
      menuItemsFilter: action.payload.data
    };
  }
  if (action.type === "menu_items_filter_remove") {
    return {
      ...state,
      menuItemsFilter: null
    };
  }
  if(action.type === 'logout'){
    return {
      isLoggedIn: false, msg: ""
    }
  }
  if(action.type === 'product_item_add'){
    return {
      ...state,
      item: action.payload.item
    }
  }
  if(action.type === 'menu_modifiers'){
    return {
      ...state,
      menuModifiers: action.payload.item
    }
  }
  if(action.type === 'virtualDinning'){
    return {
      ...state,
      virtualDinning: action.payload.data
    }
  }
  return state;
}

export function userLoginResidentDispatch(data) {
  return {
    type: "login_success_resident",
    payload: {
      data,
    },
  };
}

export function userLoginErrorResidentDispatch(data) {
  return {
    type: "login_fail_resident",
    payload: {
      data,
    },
  };
}

export function userLoginVirtualDinningDispatch(data) {
  sessionStorage.setItem("virtualDinningToken", data.ReturnToken);
  return {
    type: "login_success_virtualDinning",
    payload: {
      data,
    },
  };
}

export function menuItemsByLocation(data) {
  return {
    type: "menu_items",
    payload: {
      data,
    },
  };
}

export function menuItemsByLocationFilter(data) {
  return {
    type: "menu_items_filter",
    payload: {
      data,
    },
  };
}
export function menuItemsByLocationFilterRemove() {
  return {
    type: "menu_items_filter_remove",
  };
}

export function userLoginErrorDispatch(data) {
  return {
    type: "login_fail_resident",
    payload: {
      user: {
        loginUser: false,
        msg: "Your email address or password is not correct",
      },
    },
  };
}
export function setProduct(item) {
  return {
    type: "product_item_add",
    payload: {
      item
    },
  };
}
export function menuModifiersDispatch(item) {
  return {
    type: "menu_modifiers",
    payload: {
      item
    },
  };
}

export function virtualDinning(data) {
  return {
    type: "virtualDinning",
    payload: {
      data
    },
  };
}

export function userLogout() {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("residentToken");
  sessionStorage.removeItem("virtualDinningToken");
  return {
    type: "logout",
  };

}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch {}
};
