import React from "react";
import { useNavigate } from "react-router";

//  Icon import
import userLogo from "../../assets/img/user.png";
import logOut from "../../assets/img/logOut.png";
import BugIcon from "../../assets/img/bug.png";
// Styled
import { HeaderWrapper } from "./Header.styled";
// Components
import { Search } from "../SearchBar";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/user/userSlice";
import { deliverLogout } from "../../redux/deliver/deliverSlice";
import { orderLogout } from "../../redux/order/orderSlice";

export const Header = ({
  showHeader,
  showBugIcon,
  orderView,
  setOrderView,
}) => {
  const user = useSelector((state) => state.user);
  const orderCount = useSelector((state) => state.order);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handlerLogOut = () => {
    dispatch(userLogout());
    dispatch(deliverLogout());
    dispatch(orderLogout());
    sessionStorage.removeItem("MenuItem");
    sessionStorage.removeItem("MenuModifiers");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("virtualDinning");
    sessionStorage.removeItem("loginResident");
    navigate("/login");
  };

  const handlerBug = () => {
    if (orderCount.MenuItems.length >= 1) {
      setOrderView(true);
    }
  };
  const deliverStorage = JSON.parse(sessionStorage.getItem("deliverData"));

  return showHeader ? (
    <HeaderWrapper>
      <div className="header_info">
        <div className="header_logo">
          <p>Sultan™ Go</p>
        </div>
        <div className={`header_icons ${user.isLoggedIn ? "show" : "none"}`}>
          {showBugIcon ? (
            <div style={{ display: "inline-block", position: "relative" }}>
              <img
                src={BugIcon}
                alt="BugIcon"
                onClick={handlerBug}
                style={{ cursor: "pointer" }}
              />

              {orderCount?.MenuItems.length === 0 ? null : (
                <span className="orederCount">
                  {orderCount?.MenuItems.length}
                </span>
              )}
            </div>
          ) : (
            <img src={userLogo} alt="userLogo" />
          )}
          <button onClick={handlerLogOut}>
            <img src={logOut} alt="log_out" />
          </button>
        </div>
      </div>
      {deliverStorage ? <Search /> : null}
    </HeaderWrapper>
  ) : null;
};
