
const getLocalStorage = () => {
  let order = sessionStorage.getItem("deliver");
  if (order) {
    return JSON.parse(order);
  } else {
    return {
      data: null,
      mealType: null,
      time: null,
      deliveryMethod: null,
      paymentMethod: null,
      totalAmountForMenu: 0,
      totalPointsForMenu: 0,
    };
  }
};

export const initialDeliver = getLocalStorage();

export function deliverReducer(state = {}, action) {
  
  if (action.type === "totalAmountForMenu") {

    let amountPaylod = Math.floor(action.payload.data * 100) / 100 
    let amountPrice = Math.floor(amountPaylod + state.totalAmountForMenu )
    return {
      ...state,
      totalAmountForMenu: amountPrice,
    };
  }
  if (action.type === "totalPointsForMenu") {
    let amountPoints = (Math.floor(((((action.payload.data )* 100) / 100 ) + state.totalPointsForMenu)))
    return {
      ...state,
      totalPointsForMenu:  amountPoints ,
    };
  }
  if (action.type === "delivery_data") {
    return {
      ...state,
      data: action.payload.data,
    };
  }
  if (action.type === "meal_type") {
    return {
      ...state,
      mealType: action.payload.data,
    };
  }
  if (action.type === "delivery_time") {
    return {
      ...state,
      time: action.payload.data,
    };
  }
  if (action.type === "delivery_method") {
    return {
      ...state,
      deliveryMethod: action.payload.data,
    };
  }
  if (action.type === "payment_method") {
    return {
      ...state,
      paymentMethod: action.payload.data,
    };
  }
  if (action.type === "logout") {
    return {
      data: null,
      mealType: null,
      time: null,
      deliveryMethod: null,
      paymentMethod: null,
    };
  }

  return state;
}

export function totalPointsForMenuDispatch(data) {
  
  return {
    type: "totalPointsForMenu",
    payload: {
      data,
    },
  };
}
export function totalAmountForMenuDispatch(data) {
  return {
    type: "totalAmountForMenu",
    payload: {
      data,
    },
  };
}


export function deliveryDataDispatch(data) {
  return {
    type: "delivery_data",
    payload: {
      data,
    },
  };
}

export function deliveryTimeDispatch(data) {
  return {
    type: "delivery_time",
    payload: {
      data,
    },
  };
}

export function mealTypeDispatch(data) {
  return {
    type: "meal_type",
    payload: {
      data,
    },
  };
}

export function deliveryMethodDispatch(data) {
  return {
    type: "delivery_method",
    payload: {
      data,
    },
  };
}

export function paymentMethodDispatch(data) {
  return {
    type: "payment_method",
    payload: {
      data,
    },
  };
}

export function deliveryStateLocalStorage(state) {
  let deliverData = JSON.stringify(state);
  sessionStorage.setItem("deliverData", deliverData);
}

export function deliverLogout() {
  sessionStorage.removeItem("deliverData");
  return {
    type: "logout",
  };
}
