import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deliveryTimeDispatch } from "../../redux/deliver/deliverSlice";
import { orderDeliveryTimeDispatch } from "../../redux/order/orderSlice";
import {
  DataWrapper,
  LabelWrapper,
  SetDataWrapper,
} from "./DeliveryTime.styled";

export const DeliveryTime = ({
  mealTimeinputs,
  errorMsg,
  errorMsgCheck,
  setErrorMsgCheck,
}) => {
  const stateDeliveryMeal = useSelector((state) => state.deliver.mealType);
  const [dataTimes, setDataTimes] = useState("");
  const [timeDiv, setTimeDiv] = useState(false);
  const [time, setTime] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (stateDeliveryMeal === "Lunch") {
      const lunchTime = [
        "11:00 - 11:30",
        "11:30 - 12:00",
        "12:00 - 12:30",
        "12:30 - 13:00",
      ];
      setDataTimes(lunchTime);
    } else if (stateDeliveryMeal === "Breakfast") {
      const breakfastTime = [
        "7:00 - 7:30",
        "7:30 - 8:00",
        "8:00 - 8:30",
        "8:30 - 9:00",
      ];
      setDataTimes(breakfastTime);
    } else if (stateDeliveryMeal === "Dinner") {
      const dinnerTime = [
        "17:00 - 17:30",
        "17:30 - 18:00",
        "18:00 - 18:30",
      ];
      setDataTimes(dinnerTime);
    }
  }, []);

  const clickShowTime = () => {
    setTimeDiv(!timeDiv);
  };

  const clickHideTime = () => {
    setTimeDiv(!timeDiv);
  };

  const TimeNumber = (item) => {
    setTime(item);
    setErrorMsgCheck(false);
    dispatch(deliveryTimeDispatch(item));
    dispatch(orderDeliveryTimeDispatch(item));
    clickHideTime();
  };
  return (
    <DataWrapper>
      <LabelWrapper htmlFor="dataDelivery" onClick={clickShowTime}>
        <input
          id="dataDelivery"
          placeholder={time ? time : "Time"}
          aria-label={"dataDelivery"}
          disabled="disabled"
          ref={mealTimeinputs}
          value={time && time}
        />
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9943 20.5944C14.2751 20.5944 16.4625 19.6884 18.0756 18.0756C19.6884 16.4627 20.5944 14.2754 20.5944 11.9943C20.5944 9.71332 19.6884 7.52623 18.0756 5.91309C16.4627 4.30032 14.2754 3.39429 11.9943 3.39429C9.71332 3.39429 7.52623 4.30032 5.91309 5.91309C4.30032 7.52599 3.39429 9.71332 3.39429 11.9943C3.39683 14.2745 4.30365 16.4604 5.9159 18.0729C7.52812 19.6851 9.71407 20.5919 11.9944 20.5945L11.9943 20.5944ZM11.9943 4.59429V4.59442C13.9569 4.59442 15.8391 5.37401 17.227 6.76179C18.6148 8.14958 19.3944 10.0318 19.3944 11.9945C19.3944 13.9572 18.6148 15.8393 17.227 17.2272C15.8392 18.6149 13.957 19.3945 11.9943 19.3945C10.0317 19.3945 8.14954 18.6149 6.76166 17.2272C5.37388 15.8394 4.59429 13.9572 4.59429 11.9945C4.5963 10.0325 5.37669 8.15139 6.76392 6.76419C8.15129 5.37696 10.0323 4.59655 11.9942 4.59456L11.9943 4.59429Z"
            fill="#484545"
          />
          <path
            d="M11.7718 12.5544L15.772 14.1544C15.8428 14.182 15.9183 14.1956 15.9944 14.1945C16.1815 14.1941 16.3578 14.1064 16.471 13.9573C16.5842 13.8084 16.6215 13.615 16.5715 13.4346C16.5217 13.2542 16.3904 13.1074 16.2167 13.0376L12.5944 11.5881V7.19441C12.5944 6.97999 12.48 6.78192 12.2944 6.67478C12.1088 6.56764 11.88 6.56764 11.6944 6.67478C11.5088 6.78192 11.3944 6.98 11.3944 7.19441V11.9944C11.3936 12.1149 11.4292 12.2328 11.4966 12.3327C11.564 12.4328 11.66 12.5099 11.772 12.5544L11.7718 12.5544Z"
            fill="#484545"
          />
        </svg>
      </LabelWrapper>
      <span className="error-type">{errorMsgCheck ? errorMsg : null}</span>
      {timeDiv ? (
        <SetDataWrapper className="setData">
          <h3>Select a time</h3>
          <button className="seData-close" onClick={clickHideTime}>
            <svg
              width={10}
              height={10}
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.26512 9.25714L5.00011 5.52466L8.73511 9.25714L9.45707 8.53517L5.72459 4.80018L9.45707 1.06518L8.73511 0.343216L5.00011 4.0757L1.26512 0.343216L0.543152 1.06518L4.27563 4.80018L0.543152 8.53517L1.26512 9.25714Z"
                fill="black"
              />
            </svg>
          </button>
          <div className="setData-days">
            {dataTimes?.map((item, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    TimeNumber(item);
                  }}
                >
                  {item}
                </button>
              );
            })}
          </div>
        </SetDataWrapper>
      ) : null}
    </DataWrapper>
  );
};
