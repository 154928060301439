import styled from "styled-components";

export const CategoriesBtn = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 24px;

  .filter-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 8px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    text-transform: capitalize;
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    .category-item {
      width: auto !important;
    }
  }
`;
