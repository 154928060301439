/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from "react";
import {
  MenuWrapper,
  MenuItemDiv,
  MennuTitle,
  SingleProductWrapper,
} from "./MenuStyled";
import ArrowLogo from "../../assets/img/arrow.png";
import testImg from "../../assets/img/test.png";
import noImage from "../../assets/img/noImage.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getProduct, setProduct } from "../../redux/user/userSlice";
import closeLogo from "../../assets/img/close.png";
import {
  orderMenuItemsDispatch,
  orderTotalAmountDispatch,
  orderTotalPointsDispatch,
} from "../../redux/order/orderSlice";
import { SingleProduct } from "../../pages/SingleProduct/SingleProduct";
import { OrderPage } from "../../pages/Order/OrderPage";
const Menu = ({
  // items, dataMenu, menuGroupList,
  itemFilter,
  setItemFilter,
  allProduct,
  setOrderView,
  orderView,
}) => {
  const [menuGroupListItem, setMenuGroupListItem] = useState(null);

  const menuItemFilter = useSelector((state) => state.user.menuItemsFilter);
  useEffect(() => {
    if (menuItemFilter) {
      setItemFilter(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemFilter]);

  useEffect(() => {
    if (itemFilter) {
      if (itemFilter.length === 1) {
        const { MenuTypeName, MenuGroupList } = itemFilter[0];
        setMenuGroupListItem(MenuGroupList);
      } else if (itemFilter.length > 1) {
        const menuGroup = [];
        itemFilter.map((item) => {
          return menuGroup.push(...item.MenuGroupList);
        });

        setMenuGroupListItem(menuGroup);
      }
    }
  }, [itemFilter]);

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  // Single
  const [singleProductView, setSingleProductView] = useState(null);

  const [singleProduct, setSingleProduct] = useState(null);

  const handleProduct = (item) => {
    dispatch(setProduct(item));
    setSingleProduct(item.MenuGroupName);
    setSingleProductView(true);
  };

  if (orderView) {
    return <OrderPage setOrderView={setOrderView} />;
  }

  if (singleProductView) {
    return (
      <SingleProduct
        singleProduct={singleProduct}
        setSingleProductView={setSingleProductView}
      />
    );
  }

  if (itemFilter) {
    return (
      <MenuWrapper>
        {menuGroupListItem &&
          menuGroupListItem.map((menuItem, index) => {
            const { MenuGroupName, Icon, MenuItemList } = menuItem;
            const isSelected = selected === index;
            let checkIndex = index;
            if (true)
              return (
                <div key={index}>
                  <article className="menu-item" onClick={() => toggle(index)}>
                    <img
                      src={Icon ? `data:image/png;base64,${Icon}` : testImg}
                      alt="photo"
                      className="photo"
                    />
                    <div className="item-info">
                      <header>
                        <h4>{MenuGroupName}</h4>
                      </header>
                    </div>
                    <p className={`arrow_icon ${isSelected && "show"}`}>
                      <img src={ArrowLogo} alt="ArrowLogo" />
                    </p>
                  </article>
                  <div className={`content ${isSelected && "show"}`}>
                    {MenuItemList.map((item, index) => {
                      const {
                        AllowModification,
                        BgColor,
                        Comments,
                        Description,
                        FontColor,
                        Icon,
                        IsAlcohol,
                        MenuGroupID,
                        MenuGroupName,
                        MenuItem,
                        MenuItemID,
                        NoPrint,
                        Points,
                        Price,
                      } = item;

                      return (
                        <MenuItemDiv key={index}>
                          <div className="menuItem-wrapper">
                            <div className="menuItem-logo">
                              <img
                                src={
                                  Icon
                                    ? `data:image/png;base64,${Icon}`
                                    : noImage
                                }
                                alt={MenuItem}
                              />
                            </div>
                            <div className="menuItem-info">
                              <div className="menuItem-title">
                                <p>{MenuItem}</p>
                              </div>
                              <div className="menuItem-desc">
                                <span>{Description}</span>
                              </div>
                            </div>
                            <div className="menuItem-buy">
                              <div className="menuItem-price">
                                <p>
                                  {Points
                                    ? Points + " points " + "($ " + Price + ")"
                                    : "$ " + Price + ""}
                                </p>
                              </div>
                              <button onClick={() => handleProduct(item)}>
                                +
                              </button>
                            </div>
                          </div>
                        </MenuItemDiv>
                      );
                    })}
                  </div>
                </div>
              );
          })}
      </MenuWrapper>
    );
  }

  if (menuItemFilter) {
    return (
      <MenuWrapper>
        <div>
          <div className={"show"}>
            {menuItemFilter.map((item, index) => {
              const {
                AllowModification,
                BgColor,
                Comments,
                Description,
                FontColor,
                Icon,
                IsAlcohol,
                MenuGroupID,
                MenuGroupName,
                MenuItem,
                MenuItemID,
                NoPrint,
                Points,
                Price,
              } = item;
              return (
                <MenuItemDiv key={index}>
                  <div className="menuItem-wrapper">
                    <div className="menuItem-logo">
                      <img
                        src={Icon ? `data:image/png;base64,${Icon}` : noImage}
                        alt={MenuItem}
                      />
                    </div>
                    <div className="menuItem-info">
                      <div className="menuItem-title">
                        <p>{MenuItem}</p>
                      </div>
                      <div className="menuItem-desc">
                        <span>{Description}</span>
                      </div>
                    </div>
                    <div className="menuItem-buy">
                      <div className="menuItem-price">
                        <p>
                          {Points
                            ? Points + " points" + "($ " + Price + ")"
                            : "$ " + Price + ""}
                        </p>
                      </div>
                      <button onClick={() => handleProduct(item)}>+</button>
                    </div>
                  </div>
                </MenuItemDiv>
              );
            })}
          </div>
        </div>
      </MenuWrapper>
    );
  }

  return (
    <MenuWrapper>
      {allProduct &&
        allProduct.map((menuItem, index) => {
          const { MenuGroupName, MenuItemList } = menuItem;
          const isSelected = selected === index;
          let checkIndex = index;
          if (true)
            return (
              <div key={index}>
                <article className="menu-item" onClick={() => toggle(index)}>
                  <img src={testImg} className="photo" alt="photo" />
                  <div className="item-info">
                    <header>
                      <h4>{MenuGroupName}</h4>
                    </header>
                  </div>
                  <p className={`arrow_icon ${isSelected && "show"}`}>
                    <img src={ArrowLogo} alt="ArrowLogo" />
                  </p>
                </article>
                <div className={`content ${isSelected && "show"}`}>
                  {MenuItemList.map((item, index) => {
                    const {
                      AllowModification,
                      BgColor,
                      Comments,
                      Description,
                      FontColor,
                      Icon,
                      IsAlcohol,
                      MenuGroupID,
                      MenuGroupName,
                      MenuItem,
                      MenuItemID,
                      NoPrint,
                      Points,
                      Price,
                    } = item;

                    return (
                      <MenuItemDiv key={index}>
                        <div className="menuItem-wrapper">
                          <div className="menuItem-logo">
                            <img
                              src={
                                Icon ? `data:image/png;base64,${Icon}` : noImage
                              }
                              alt={MenuItem}
                            />
                          </div>
                          <div className="menuItem-info">
                            <div className="menuItem-title">
                              <p>{MenuItem}</p>
                            </div>
                            <div className="menuItem-desc">
                              <span>{Description}</span>
                            </div>
                          </div>
                          <div className="menuItem-buy">
                            <div className="menuItem-price">
                              <p>
                                {Points
                                  ? Points + " points " + "($ " + Price + ")"
                                  : "$ " + Price + ""}
                              </p>
                            </div>
                            <button onClick={() => handleProduct(item)}>
                              +
                            </button>
                          </div>
                        </div>
                      </MenuItemDiv>
                    );
                  })}
                </div>
              </div>
            );
        })}
    </MenuWrapper>
  );
};
export default Menu;
