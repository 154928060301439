import styled from "styled-components";
import { device } from "../../themes/breakpoints";
export const LoginWrapper = styled.div`
  & form {
      display: flex;
      flex-direction: column;
      max-width: 280px;
      margin: 0 auto;
      padding-top: 140px;
      input {
        padding: 16px 41px 16px 24px;
        gap: 10px;
        background: #ffffff;
        border: 1px solid #acaab3;
        border-radius: 45px;
        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          /* identical to box height */
          color: #797584;
        }
        &:nth-child(2) {
          margin-top: 29px;
        }
      }
      button {
        text-align: center;
        background: #4d7443;
        border-radius: 140px;
        padding: 17px 0;
        margin-top: 76px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #ffffff;
        margin-bottom: 25px;
      }
      .errorMsg {
        color: #cc0606;
        font-size: 10px;
        margin-top: 15px;
      }
    }

`;
