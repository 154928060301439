import styled from "styled-components";

export const OrderWrapper = styled.div`
  padding: 0 20px;
  padding-top: 41px;
  height: 100%;
  .order_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #4d7443;
    text-align: center;
  }
  .flex {
    display: flex;
    align-items: center;
    column-gap: 10px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #797584;
    }
  }
  .order_sub {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .order_dinning_date_wrapper {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    .order_btn_wrapper {
      margin-top: 49px;
      &.zzz {
        .order_btn {
          background: #ffffff;
          color: #4d7443;
        }
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-top: -14px;
      }
    }
  }
  .order_meal_type_wrapper {
    margin-top: 27px;
    &.qqq {
      margin-top: 76px;
    }
  }
  .order_meal_type_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #484545;
    margin-top: 24px;
  }
  .order_dinning_date {
    max-width: calc(100% - 20px);
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
  }
  .order_product_wrapper {
    margin-top: 24px;
    .order_product {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .order_product_header {
        display: flex;
        align-items: center;
        .prd_quanta {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
        .prd_title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #484545;
          margin-left: 20px;
        }
        .prd_price {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          color: #000000;
          margin-left: auto;
        }
      }
      .order_product_body {
        margin-top: 10px;
      }
      .order_product_footer {
        display: flex;
        justify-content: space-between;
        margin-top: 7px;
        .add_prd_btn {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #d3e9dd;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          color: #4d7443;
        }
      }
    }
  }
  .order_total {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
    }
  }
`;
export const CheckBoxWrapper = styled.div`
  position: relative;
  width: auto;
  display: flex;
  margin: 0 auto;
  width: 125px;
  justify-content: space-between;
  align-items: center;
  margin-top: 34px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #484545;
  }
`;
export const CheckBoxLabel = styled.label`
  width: 34px;
  height: 17px;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #4d7443;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: -0.5px;
    background: #4d7443;
    box-shadow: 1px 3px 3px 1px rgba (0, 0, 0, 0.2);
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 0px;
  height: 0px;
  &:checked + ${CheckBoxLabel} {
    background: #4d7443;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 16px;
      transition: 0.2s;
      background: #fff;
    }
  }
`;

export const OrderDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 9;
  .go_prd {
    position: absolute;
    top: 18px;
    left: 17px;
    cursor: pointer;
    z-index: 9;
    width: 24px;
  }
  .type-popUp-wrapper {
    padding-left: 40px;
    padding-right: 48px;
    padding-top: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .type-popUp-text {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        font-weight: 600;
      }
      p {
        padding-top: 100px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #797584;
      }
    }
    .type-popUp-btn {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: 14px;
      button {
        width: 100%;
        max-width: 338px;
        margin: 0 auto;
        border-radius: 140px;
        padding-top: 15px;
        padding-bottom: 12px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        &:first-child {
          background: #4d7443;
          color: #fff;
        }
        &:last-child {
          margin-top: 12px;
          background: #ffffff;
          color: #4d7443;
        }
      }
    }
  }

  .type-popUp {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
  }
  .cancel_remove {
    position: absolute;
    top: 16px;
    right: 25px;
    cursor: pointer;
  }
`;

export const StatusPopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9;
  width: 100%;
  height: 100%;
  .type-popUp-wrapper-status {
    padding-left: 40px;
    padding-right: 48px;
    padding-top: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .type-popUp-text-status {
      span {
        display: block;
        max-width: 230px;
        width: 100%;
        margin: 0 auto;
        padding-top: 106px;
        text-align: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        text-align: center;

        color: #000000;
      }
      p {
        max-width: 160px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        padding-top: 138px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        /* or 150% */

        text-align: center;

        color: #797584;
      }
    }
    .type-popUp-btn-status {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: 14px;
      button {
        width: 100%;
        max-width: 338px;
        margin: 0 auto;
        border-radius: 140px;
        padding-top: 15px;
        padding-bottom: 12px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        &:first-child {
          background: #4d7443;
          color: #fff;
        }
      }
    }
  }

  .type-popUp-status {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    left: 0;
  }
  .cancel_remove-status {
    position: absolute;
    top: 16px;
    right: 25px;
    cursor: pointer;
  }
`;
