import { AppConfigurationClient } from "@azure/app-configuration";
import React from "react";
import { Login } from "../../components/LoginForm";
import { useConfiguration, useFeatureFlag } from "../../hook/useFeatureFlag";

export const LoginPage = ({ setShowHeader }) => {
  setShowHeader(true);
  return (
    <>
      <Login />
    </>
  );
};
