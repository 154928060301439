import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { SeacrhWrapper } from "./SearchBar.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  menuItemsByLocationFilter,
  menuItemsByLocationFilterRemove,
} from "../../redux/user/userSlice";
export const Search = () => {
  const [title, setTitle] = useState("");

  const menuItems = useSelector((state) => state.user.menuItems);
  const dispatch = useDispatch();
  const menuItemsFilter = () => {
    const menuItemsFilter = [];
    menuItems.filter((items, indexItem) => {
      items.MenuGroupList.filter((menGrp, indexGrp) => {
        menGrp.MenuItemList.filter((menuItem, indexMenItem) => {
          if (menuItem.MenuItem.toLowerCase().includes(title.toLowerCase())) {
            let obj = {
              MenuTypeName: items.MenuTypeName,
              MenuGroupList: [
                {
                  MenuGroupName: items.MenuGroupList[indexGrp].MenuGroupName,
                  MenuItemList: [
                    items.MenuGroupList[indexGrp].MenuItemList[indexMenItem],
                  ],
                },
              ],
            };
            menuItemsFilter.push(items.MenuGroupList[indexGrp].MenuItemList[indexMenItem]);
          }
        });
      });
    });
    if (
      typeof menuItemsFilter !== "undefined" &&
      menuItemsFilter.length === 0
    ) {
      return dispatch(menuItemsByLocationFilterRemove());
    }
    let asd = [];

    menuItemsFilter.map((item,index) => {

    });
    dispatch(menuItemsByLocationFilter(menuItemsFilter));
  };
  useEffect(() => {
    if (title.length > 2) {
      const handle = setTimeout(() => {
        menuItemsFilter();
      }, 500);
      return () => {
        clearTimeout(handle);
      };
    }
    if (title.length === 0) {
      const handle = setTimeout(() => {
        dispatch(menuItemsByLocationFilterRemove());
      }, 500);
      return () => {
        clearTimeout(handle);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
  return (
    <SeacrhWrapper>
      <input
        type="text"
        placeholder="Search Our Menu"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <AiOutlineSearch
        className="search_icon"
        style={{
          position: "absolute",
          top: "7px",
          right: "14px",
          width: "24px",
          height: "24px",
          color: "#7D7B7B",
        }}
      />
    </SeacrhWrapper>
  );
};
