import React from 'react'
import { LoaderWrapper } from './Loading.styled'

const Loading = () => {
 return (
  <LoaderWrapper className="loader">
  </LoaderWrapper>
 )
}

export default Loading