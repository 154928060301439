import styled from "styled-components";
import { device } from "../../themes/breakpoints";

export const HeaderWrapper = styled.header`

    width: 100%;
    background: #4d7443;
    padding: 14px 27px;
    .orederCount {
      position: absolute;
      background: red;
      color: #ffffff;
      top: -8px;
      right: -2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    .header {
      &_info {
        display: flex;
        justify-content: space-between;
      }
      &_logo {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }
      }
      &_icons {
        button {
          background: none;
        }
        &.show {
          display: block;
        }
        &.none {
          display: none;
        }
      }
    }
`;
