import React from "react";
import { useState } from "react";
import { DeliveryData } from "../../components/DeliveryData/DeliveryData";
import { DeliveryMethod } from "../../components/DeliveryMethod/DeliveryMethod";
import { DeliveryTime } from "../../components/DeliveryTime/DeliveryTime";
// import { MealType } from "../../components/DeliveryMealType/MealType";
import { DelWrapper } from "./DeliveryType.styled";
import { DeliveryPayment } from "../../components/DeliveryPayment/DeliveryPayment";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  deliveryMethodDispatch,
  deliveryStateLocalStorage,
  paymentMethodDispatch,
} from "../../redux/deliver/deliverSlice";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { mealTypeDispatch } from "../../redux/deliver/deliverSlice";
import {
  orderChargeTypeDispatch,
  orderDeliveryTimeDispatch,
  orderDeliveryTypeDispatch,
  orderMealTypeDispatch,
  orderMenuItemsAllChangeDispatch,
  orderTotalAmountInitialDispatch,
  orderTotalPointsInitialDispatch,
} from "../../redux/order/orderSlice";
import { useRef } from "react";

export const DelPartner = () => {
  const [step, setStep] = useState(1);
  const [errorMsgCheck, setErrorMsgCheck] = useState(false);

  if (sessionStorage.getItem("MenuItem") !== null) {
    sessionStorage.removeItem("MenuItem");
  }
  if (sessionStorage.getItem("MenuModifiers") !== null) {
    sessionStorage.removeItem("MenuModifiers");
  }

  const dispatch = useDispatch();

  const orderData = useSelector((state) => state.order);
  if (orderData.MenuItems.length !== 0) {
    dispatch(orderMenuItemsAllChangeDispatch([]));
    dispatch(orderTotalPointsInitialDispatch(0));
    dispatch(orderTotalAmountInitialDispatch(0));
  }

  const changeStep = (e) => {
    e.preventDefault();
    if (step === 2) {
      let asd = mealTypeinputs.current.childNodes;
      let arr = [...asd];
      let bool = true;
      arr.some((item, index) => {
        if (item.childNodes[1].children[0].checked) {
          setMealType(item.childNodes[1].children[0].value);
          dispatch(mealTypeDispatch(item.childNodes[1].children[0].value));
          dispatch(orderMealTypeDispatch(item.childNodes[1].children[0].value));
          setErrorMsgCheck(false);
          bool = false;
          setStep(step + 1);
          return null;
        } else if (bool) {
          setErrorMsgCheck(true);
        }
      });
    } else if (step === 3) {
      let asd = mealMethodinputs.current.childNodes;
      let arr = [...asd];
      let bool = true;
      arr.map((item, index) => {
        if (item.childNodes[1].children[0].checked) {
          setMealType(item.childNodes[1].children[0].value);
          dispatch(
            deliveryMethodDispatch(item.childNodes[1].children[0].value)
          );
          dispatch(
            orderDeliveryTypeDispatch(item.childNodes[1].children[0].value)
          );
          setErrorMsgCheck(false);
          bool = false;
          if (chooseTypeSession.SkipDeliveryTime === true) {
            setErrorMsgCheck(false);
            dispatch(orderDeliveryTimeDispatch(""));
            setStep(step + 2);
            return null;
          } else {
            setStep(step + 1);
            return null;
          }
        } else if (bool) {
          setErrorMsgCheck(true);
        }
      });
    } else if (step === 4) {
      if (mealTimeinputs.current.value) {
        setErrorMsgCheck(false);
        setStep(step + 1);
      } else {
        setErrorMsgCheck(true);
      }
    } else if (step === 5) {
      let asd = mealPaymentinputs.current.childNodes;
      let arr = [...asd];
      let bool = true;
      arr.map((item, index) => {
        if (item.childNodes[1].children[0].checked) {
          setMealType(item.childNodes[1].children[0].value);
          dispatch(
            orderChargeTypeDispatch(item.childNodes[1].children[0].value)
          );
          dispatch(paymentMethodDispatch(item.childNodes[1].children[0].value));
          setErrorMsgCheck(false);
          bool = false;
          setStep(step + 1);
          return null;
        } else if (bool) {
          setErrorMsgCheck(true);
        }
      });
    } else {
      setStep(step + 1);
    }
  };
  const navigate = useNavigate();

  const deliverData = useSelector((state) => state.deliver);
  const userResident = useSelector((state) => state.user.userResident);
  const chooseType = useSelector((state) => state.user.virtualDinning);
  const chooseTypeSession = JSON.parse(
    sessionStorage.getItem("virtualDinning")
  );
  const logRes = JSON.parse(sessionStorage.getItem("loginResident"));
  console.log("logRes :", logRes);

  useEffect(() => {
    if (step === 6) {
      deliveryStateLocalStorage(deliverData);
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const clickCheckValue = () => {};

  const [mealType, setMealType] = useState("");
  const errorMsg = "Please complete all selections";
  // const handleChange = (e) => {
  //   setErrorMsgCheck(false);
  // };

  const mealTypeinputs = useRef(null);
  const mealTimeinputs = useRef(null);
  const mealMethodinputs = useRef(null);
  const mealPaymentinputs = useRef(null);
  return (
    <DelWrapper className="del-header">
      <span className="orgName">{logRes?.OrgName}</span>

      <div className={step === 1 ? "del-data" : "del-none"}>
        <span>Select a delivery date</span>
        <p>
          Select for what day you are placing an order. The menu items available
          will change slightly based on the specials for the date your choose.
        </p>
      </div>
      <div
        className={
          step === 4 && chooseTypeSession.SkipDeliveryTime === false
            ? "del-data"
            : "del-none"
        }
      >
        <span>Select a time</span>
        <p>Select for a time for dining in or pickup.</p>
      </div>
      {/* <form action="#"> */}
      {step === 1 ? (
        <DeliveryData chooseTypeSession={chooseTypeSession} />
      ) : step === 2 ? (
        // <MealType setErrorMsg={setErrorMsg} />
        <div className="type-wrapper">
          <p className="type-title"> Choose Meal Type </p>
          <div className="type-container" ref={mealTypeinputs}>
            {(chooseType?.Breakfast || chooseTypeSession?.Breakfast) && (
              <label className="container">
                <span className="type">Breakfast</span>
                <span className="mealType-input">
                  <input
                    type="radio"
                    name="meal"
                    onChange={() => {
                      setErrorMsgCheck(false);
                    }}
                    value="Breakfast"
                  />
                  <span className="checkmark"></span>
                </span>
              </label>
            )}

            {(chooseType?.Lunch || chooseTypeSession?.Lunch) && (
              <label className="container">
                <span className="type">Lunch</span>
                <span className="mealType-input">
                  <input
                    type="radio"
                    name="meal"
                    onChange={() => {
                      setErrorMsgCheck(false);
                    }}
                    value="Lunch"
                  />
                  <span className="checkmark"></span>
                </span>
              </label>
            )}

            {(chooseType?.Dinner || chooseTypeSession?.Dinner) && (
              <label className="container">
                <span className="type">Dinner</span>
                <span className="mealType-input">
                  <input
                    type="radio"
                    name="meal"
                    onChange={() => {
                      setErrorMsgCheck(false);
                    }}
                    value="Dinner"
                  />
                  <span className="checkmark"></span>
                </span>
              </label>
            )}
          </div>
          <span className="error-type">{errorMsgCheck ? errorMsg : null}</span>
        </div>
      ) : step === 3 ? (
        <DeliveryMethod
          chooseType={chooseType}
          chooseTypeSession={chooseTypeSession}
          mealMethodinputs={mealMethodinputs}
          setErrorMsgCheck={setErrorMsgCheck}
          errorMsgCheck={errorMsgCheck}
          errorMsg={errorMsg}
        />
      ) : step === 4 ? (
        chooseTypeSession.SkipDeliveryTime === false ? (
          <DeliveryTime
            setErrorMsgCheck={setErrorMsgCheck}
            errorMsgCheck={errorMsgCheck}
            errorMsg={errorMsg}
            mealTimeinputs={mealTimeinputs}
          />
        ) : null
      ) : step === 5 ? (
        <DeliveryPayment
          mealPaymentinputs={mealPaymentinputs}
          setErrorMsgCheck={setErrorMsgCheck}
          errorMsgCheck={errorMsgCheck}
          errorMsg={errorMsg}
        />
      ) : null}

      <button className="step-btn" onClick={(e) => changeStep(e)}>
        {step === 5 ? "Menu" : "Continue"}
      </button>
      {/* </form> */}
    </DelWrapper>
  );
};
