import styled from "styled-components";

export const SingleProductWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9;
  width: 100%;
  height: 100%;
  .prd {
    width: 100%;
    margin: 0 auto;
    padding: 0 18px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &_header {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: end;
      button {
        padding-top: 15px;
      }
    }
    &_body {
      padding-top: 97px;
      text-align: center;
      img {
        max-width: 114px;
        max-height: 119px;
        width: auto;
        height: auto;
        object-fit: contain;
        text-align: center;
      }
      .prd_title {
        text-align: left;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #484545;
        margin-top: 60px;
      }
      .prd_price {
        text-align: left;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-top: 4px;
      }
      .prd_desc {
        max-width: 266px;
        margin-top: 12px;
        width: 100%;
        word-wrap: break-word;
        text-align: left;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #797584;
      }
    }
    &_footer {
      margin-bottom: 14px;
      .add_prd {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-bottom: 27px;
        button {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 17px;
          &.add_prd_inc {
            background: #d3e9dd;
            color: #4d7443;
          }
          &.add_prd_dec {
            background: #d9d9d9;
            color: #403d3d;
          }
        }
      }
      button.add_cart {
        width: 100%;
        background: #4d7443;
        border-radius: 140px;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 17px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: #ffffff;
      }
    }
    &_body {
      flex: auto;
    }
    &_customize {
      text-align: left;
      padding-top: 40px;
      padding-bottom: 40px;
      & h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #484545;
      }
      &_select {
        .modifier {
          margin-top: 24px;
          .modifier_type {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #acaab3;
            .modifier_type_btn {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              background: #d3e9dd;
              border-radius: 50%;
              color: #4d7443;
              margin-bottom: 7px;
            }
            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              margin-top: 7px;
              color: #4d7443;
            }
          }
          .modifierMod {
            .container {
              display: block;
              position: relative;
              margin-bottom: 21px;
              cursor: pointer;
              font-size: 22px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }
              &:hover input ~ .checkmark {
                background-color: #f0f0f0;
              }
              & input:checked ~ .checkmark {
                background-color: #4d7443;
              }
              & input:checked ~ .checkmark:after {
                display: block;
              }
              & .checkmark:after {
                left: 6px;
                top: 3px;
                width: 4px;
                height: 7px;
                border: solid white;
                border-width: 0 1px 1px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }

              .type {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #484545;
              }
            }
            .type-container {
              opacity: 0;
              pointer-events: none;
              height: 0px;
              transition: all 0.3s;
              &.show {
                opacity: 1;
                pointer-events: all;
                height: auto;
                margin-top: 20px;
              }
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 0;
              right: 0;
              height: 17px;
              width: 17px;
              border: 1px solid #4d7443;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }
          }
        }
      }
    }
  }
`;
