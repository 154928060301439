import React from "react";
import { CategoriesBtn } from "./Categories.styled";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const Categories = ({ filterItems, allCategoriesMenu }) => {
  return (
    <CategoriesBtn>
      <Swiper
        spaceBetween={0}
        slidesPerView={4}
        loop={true}
      >
        <SwiperSlide className="category-item">
          <button className="filter-btn" onClick={() => filterItems("All")}>
            All
          </button>
        </SwiperSlide>
        {allCategoriesMenu.map((category, index) => {
          return (
            <SwiperSlide key={index} className="category-item">
              <button
                className="filter-btn"
                onClick={() => filterItems(category)}
              >
                {category}
              </button>
            </SwiperSlide>
          );
        })}

        {/* </Slider> */}
      </Swiper>
    </CategoriesBtn>
  );
};

export default Categories;
