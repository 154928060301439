import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { paymentMethodDispatch } from "../../redux/deliver/deliverSlice";
import { orderChargeTypeDispatch } from "../../redux/order/orderSlice";

export const DeliveryPayment = ({
  mealPaymentinputs,
  errorMsg,
  errorMsgCheck,
  setErrorMsgCheck,
}) => {
  const [deliveryPayment, setDeliveryPayment] = useState("");
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setErrorMsgCheck(false)
    setDeliveryPayment(e.target.value);
    dispatch(paymentMethodDispatch(e.target.value));
    dispatch(orderChargeTypeDispatch(e.target.value));
  };
  return (
    <div className="type-wrapper">
      <p className="type-title">
      Are you using meal plan credits or have us charge to your account?
      </p>
      <div className="type-container" ref={mealPaymentinputs}>
        <label className="container">
          <span className="type">Meal Plan</span>
          <span>
            <input
              type="radio"
              name="method"
              value="Meal"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </span>
        </label>
        <label className="container">
          <span className="type">Charge My Account</span>
          <span>
            <input
              type="radio"
              name="method"
              value="Charge"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </span>
        </label>
      </div>
      <span className="error-type">{errorMsgCheck ? errorMsg : null}</span>
    </div>
  );
};
