import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deliveryMethodDispatch } from "../../redux/deliver/deliverSlice";
import { orderDeliveryTypeDispatch } from "../../redux/order/orderSlice";

export const DeliveryMethod = ({
  mealMethodinputs,
  errorMsg,
  errorMsgCheck,
  setErrorMsgCheck,
  chooseType,
  chooseTypeSession,
}) => {
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [roomPopUp, setRoomPopUp] = useState(false);
  const [confirmRoom, setConfirmRoom] = useState("");
  const dispatch = useDispatch();
  const handleChange = (e, roomAnswer) => {
    setErrorMsgCheck(false);
    if (e !== "Room Service") {
      setConfirmRoom("No");
      setDeliveryMethod(e);
      dispatch(deliveryMethodDispatch(e));
      dispatch(orderDeliveryTypeDispatch(e));
    } else if (e === "Room Service") {
      if (roomAnswer === "Yes") {
        setDeliveryMethod("Room Service");
        dispatch(deliveryMethodDispatch("Room Service"));
        dispatch(orderDeliveryTypeDispatch("Room Service"));
      } else {
        setRoomPopUp(!roomPopUp);
      }
    }
  };
  const confirmRoomFunc = (e) => {
    setRoomPopUp(!roomPopUp);
    if (e.target.value === "Yes") {
      setConfirmRoom("Yes");
      handleChange("Room Service", "Yes");
    } else {
      setConfirmRoom("No");
      handleChange("Room Service", "No");
    }
  };

  const getAnswerRoom = () => {
    return confirmRoom;
  };
  return (
    <div className="type-wrapper">
      <p className="type-title">Are you dining in or picking up?</p>
      <div className="type-container" ref={mealMethodinputs}>
        {(chooseType?.DineIn  || chooseTypeSession?.DineIn) && (
          <label className="container">
            <span className="type">Dine In</span>
            <span>
              <input
                type="radio"
                name="method"
                value="Dine In"
                onClick={(e) => handleChange(e.target.value)}
              />
              <span className="checkmark"></span>
            </span>
          </label>
        )}
        {(chooseType?.Delivery || chooseTypeSession?.Delivery) && (
          <label className="container">
            <span className="type">Room Service</span>
            <span>
              <input
                type="radio"
                name="method"
                value="Room Service"
                checked={confirmRoom === "Yes" ? true : false}
                onClick={(e) => handleChange(e.target.value)}
              />
              <span className="checkmark"></span>
            </span>
          </label>
        )}
        {(chooseType?.PickUp || chooseTypeSession?.PickUp) && (
          <label className="container">
            <span className="type">Pick Up</span>
            <span>
              <input
                type="radio"
                name="method"
                value="Pick Up"
                onClick={(e) => handleChange(e.target.value)}
              />
              <span className="checkmark"></span>
            </span>
          </label>
        )}
      </div>
      <span className="error-type">{errorMsgCheck ? errorMsg : null}</span>
      {roomPopUp ? (
        <div className="type-popUp">
          <div className="type-popUp-wrapper">
            <div className="type-popUp-text">
              <p>
                Room service could involve a charge based on your community
                policy
              </p>
            </div>
            <div className="type-popUp-btn">
              <button
                type="button"
                value={"Yes"}
                onClick={(e) => confirmRoomFunc(e)}
              >
                Yes
              </button>
              <button
                type="button"
                value={"No"}
                onClick={(e) => confirmRoomFunc(e)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
