import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading/Loading";
import Categories from "../../components/Categories/Categories";
import Menu from "../../components/Menu/Menu";
import { useDispatch, useSelector } from "react-redux";
import {
  menuItemsByLocation,
  menuModifiersDispatch,
} from "../../redux/user/userSlice";
import { useNavigate } from "react-router";
import {
  orderMenuItemsAllChangeDispatch,
  orderTotalAmountInitialDispatch,
  orderTotalPointsInitialDispatch,
} from "../../redux/order/orderSlice";

export const Homepage = ({
  setShowHeader,
  setShowBugIcon,
  orderView,
  setOrderView,
}) => {
  const [dataMenu, setDataMenu] = useState(null);
  const [menuTypeName, setMenuTypeName] = useState(null);
  const [itemFilter, setItemFilter] = useState(null);
  const [loading, setLoading] = useState(null);
  const [allProduct, setAllProduct] = useState(null);
  const [menuModifiers, setMenuModifiers] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deliverStorage = JSON.parse(sessionStorage.getItem("deliverData"));
  const MenuItem = sessionStorage.getItem("MenuItem");
  const MenuModifiers = sessionStorage.getItem("MenuModifiers");
  useEffect(() => {
    if (dataMenu === null && MenuItem === null) fetchMenuJSON();
    else {
      setDataMenu(JSON.parse(MenuItem));
      separateMenuTypeName(JSON.parse(MenuItem));
      allFilter(JSON.parse(MenuItem));
      dispatch(menuItemsByLocation(JSON.parse(MenuItem)));
      setMenuModifiers(JSON.parse(MenuModifiers));
      dispatch(menuModifiersDispatch(JSON.parse(MenuModifiers)));
    }
    setShowHeader(true);
    setShowBugIcon(true);
  }, []);
  async function fetchMenuJSON() {
    if (dataMenu === null) {
      setLoading(true);
      const residentToken = sessionStorage.getItem("virtualDinningToken");
      const myHeaders = new Headers({
        Authorization: "Bearer " + residentToken,
        "Content-Type": "application/x-www-form-urlencoded",
      });
      const response = await fetch(
        `${process.env.REACT_APP_MenuURL_VirtualDining_MenuItemsByDateAndMealType}?Date=${deliverStorage.data}&MealType=${deliverStorage.mealType}`,
        {
          headers: myHeaders,
          method: "GET",
        }
      );
      const menues = await response.json();
      console.log('menues :', menues);
      setDataMenu(menues.DataArray);
      separateMenuTypeName(menues.DataArray);
      allFilter(menues.DataArray);
      dispatch(menuItemsByLocation(menues.DataArray));
      sessionStorage.setItem("MenuItem", JSON.stringify(menues.DataArray));
      fetchMenuModifiersJSON();
    }
  }

  async function fetchMenuModifiersJSON() {
    if (menuModifiers === null) {
      const residentToken = sessionStorage.getItem("virtualDinningToken");
      const myHeaders = new Headers({
        Authorization: "Bearer " + residentToken,
        "Content-Type": "application/x-www-form-urlencoded",
      });
      const response = await fetch(
        `${process.env.REACT_APP_MenuURL_VirtualDining_MenuModifiers}`,
        {
          headers: myHeaders,
          method: "GET",
        }
      );
      const menuModifiersData = await response.json();
      setMenuModifiers(menuModifiersData.DataArray);
      dispatch(menuModifiersDispatch(menuModifiersData.DataArray));
      sessionStorage.setItem(
        "MenuModifiers",
        JSON.stringify(menuModifiersData.DataArray)
      );
      setLoading(false);
    }
  }

  const separateMenuTypeName = (data) => {
    const allMenuTypeName = [
      ...new Set(data?.map((item) => item.MenuTypeName)),
    ];
    setMenuTypeName(allMenuTypeName);
  };

  const filterItems = (category) => {
    const allCategory = [];

    if (category) {
      const newItems = dataMenu.filter((item) => {
        if (item.MenuTypeName === category) {
          return item.MenuGroupList;
        }
        if (category === "All") {
          return allCategory.push(item);
        }
      });
      if (category === "All") {
        setItemFilter(allCategory);
      } else {
        setItemFilter([...newItems]);
      }
    }
  };

  const dataOrder = useSelector((state) => state.order);
  useEffect(() => {
    if (!sessionStorage.getItem("order") || dataOrder.MenuItems.length >= 1) {
      sessionStorage.setItem("order", JSON.stringify(dataOrder));
    }
  }, [dataOrder]);

  const toOrderPage = () => {
    // navigate("/order");
    setOrderView(true);
  };

  const allFilter = (data) => {
    const allCategory = [];
    data.filter((item) => {
      return allCategory.push(...item.MenuGroupList);
    });
    setAllProduct(allCategory);
  };

  if (loading) {
    return <Loading />;
  }
  const goPrd = () => {
    navigate("del");
    dispatch(orderMenuItemsAllChangeDispatch([]));
    dispatch(orderTotalPointsInitialDispatch(0));
    dispatch(orderTotalAmountInitialDispatch(0));
  };

  if (menuTypeName) {
    return (
      <div className="product_container">
        <div className="products_wrapper">
          <div className="go_prd" onClick={goPrd}>
            <svg
              width={20}
              height={12}
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#000"
            >
              <path
                d="M0.239763 6.20972L5.35988 11.6497C5.48376 11.7789 5.69559 11.78 5.81501 11.6646C5.93622 11.5474 5.94514 11.3325 5.82996 11.2095L1.21991 6.30949L19.6799 6.30949C19.8567 6.30949 20 6.16618 20 5.98939C20 5.81261 19.8567 5.6693 19.6799 5.6693L1.21991 5.6693L5.82996 0.769302C5.94514 0.646314 5.938 0.429349 5.81501 0.314172C5.69202 0.198995 5.4938 0.196092 5.35988 0.329127L0.239763 5.76907C0.118113 5.91885 0.150032 6.10233 0.239763 6.20902V6.20972Z"
                fill="#403D3D"
              />
            </svg>
          </div>
          <Categories
            allCategoriesMenu={menuTypeName}
            filterItems={filterItems}
          />
          <div className="date_del">{dataOrder.DeliveryDate}</div>
          <Menu
            itemFilter={itemFilter}
            setItemFilter={setItemFilter}
            allProduct={allProduct}
            setOrderView={setOrderView}
            orderView={orderView}
          />

          {dataOrder.MenuItems.length >= 1 ? (
            <div className="order_btn_wrapper">
              <button className="order_btn" onClick={toOrderPage}>
                Order {dataOrder.MenuItems.length} for{" "}
                {dataOrder.TotalPoints
                  ? dataOrder.TotalPoints +
                    " points " +
                    "($ " +
                    dataOrder.TotalAmount +
                    ")"
                  : "$  " + dataOrder.TotalAmount + ""}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
};
