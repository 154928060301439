import styled from "styled-components";
import { device } from "../../themes/breakpoints";
export const FooterWrapper = styled.footer`
    width: 100%;
    background: #403d3d;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    color: #cbc7c7;
    padding: 33px 0px;
    p {
      font-size: 16px;
      line-height: 19px;
    }
`;
