import styled from "styled-components";

export const SeacrhWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  margin-top: 10px;
  input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    padding: 10px 56px 10px 18px;
    &::placeholder {
      color: #7d7b7b;
    }
  }
  .search_icon{

  }
`;
