const getLocalStorage = () => {
  let order = sessionStorage.getItem("order");
  if (order) {
    return JSON.parse(order);
  } else {
    return {
      OrgCode: null,
      Location: "VIRTUALDINING",
      ResidentID: null,
      ResName: null,
      OrderDate: null,
      OrderTime: null,
      Paid: true,
      ChargeType: null,
      DeliveryDate: null,
      DeliveryTime: null,
      DeliveryType: null,
      MealType: null,
      TableID: null,
      TableName: null,
      IsGuest: false,
      TotalPoints: 0,
      TotalAmount: 0,
      MenuItems: [],
    };
  }
};

export const initialOrder = getLocalStorage();

export function orderReducer(state = {}, action) {
  if (action.type === "order_orgCode") {
    return {
      ...state,
      OrgCode: action.payload.data,
    };
  }

  if (action.type === "order_residentId") {
    return {
      ...state,
      ResidentID: action.payload.data,
    };
  }

  if (action.type === "order_resName") {
    return {
      ...state,
      ResName: action.payload.data,
    };
  }

  if (action.type === "order_orderDate") {
    return {
      ...state,
      OrderDate: action.payload.data,
    };
  }

  if (action.type === "order_orderTime") {
    return {
      ...state,
      OrderTime: action.payload.data,
    };
  }

  if (action.type === "order_chargeType") {
    return {
      ...state,
      ChargeType: action.payload.data,
    };
  }

  if (action.type === "order_deliveryDate") {
    return {
      ...state,
      DeliveryDate: action.payload.data,
    };
  }

  if (action.type === "order_deliveryTime") {
    return {
      ...state,
      DeliveryTime: action.payload.data,
    };
  }

  if (action.type === "order_deliveryType") {
    return {
      ...state,
      DeliveryType: action.payload.data,
    };
  }

  if (action.type === "order_mealType") {
    return {
      ...state,
      MealType: action.payload.data,
    };
  }

  if (action.type === "order_menuItems") {
    return {
      ...state,
      MenuItems: [...state.MenuItems, action.payload.data],
    };
  }

  if (action.type === "order_menuItemsAllChange") {
    return {
      ...state,
      MenuItems: action.payload.data,
    };
  }

  if (action.type === "order_totalPoints") {
    let totalPoints = ((state.TotalPoints + action.payload.data) * 100) / 100;
    if (totalPoints % 1 !== 0) {
      totalPoints = +totalPoints.toFixed("2");
    } else {
      totalPoints = +totalPoints.toFixed("0");
    }
    return {
      ...state,
      TotalPoints: totalPoints,
    };
  }

  if (action.type === "order_totalAmount") {
    let totalPrice = ((state.TotalAmount + action.payload.data) * 100) / 100;
    if (totalPrice % 1 !== 0) {
      totalPrice = +totalPrice.toFixed("2");
    } else {
      totalPrice = +totalPrice.toFixed("0");
    }
    return {
      ...state,
      TotalAmount: totalPrice,
    };
  }

  if (action.type === "order_totalAmountDec") {
    let totalPrice = ((state.TotalAmount - action.payload.data) * 100) / 100;
    if (totalPrice % 1 !== 0) {
      totalPrice = +totalPrice.toFixed("2");
    } else {
      totalPrice = +totalPrice.toFixed("0");
    }
    return {
      ...state,
      TotalAmount: totalPrice,
    };
  }

  if (action.type === "order_totalPointsDec") {
    let totalPoints = ((state.TotalPoints - action.payload.data) * 100) / 100;
    if (totalPoints % 1 !== 0) {
      totalPoints = +totalPoints.toFixed("2");
    } else {
      totalPoints = +totalPoints.toFixed("0");
    }
    return {
      ...state,
      TotalPoints: totalPoints,
    };
  }

  if (action.type === "order_totalPointsInitial") {
    return {
      ...state,
      TotalPoints: action.payload.data,
    };
  }

  if (action.type === "order_totalAmountsInitial") {
    return {
      ...state,
      TotalAmount: action.payload.data,
    };
  }
  if (action.type === "logout") {
    return {
      OrgCode: null,
      Location: "VIRTUALDINING",
      ResidentID: null,
      ResName: null,
      OrderDate: null,
      OrderTime: null,
      Paid: true,
      ChargeType: null,
      DeliveryDate: null,
      DeliveryTime: null,
      DeliveryType: null,
      MealType: null,
      TableID: null,
      TableName: null,
      IsGuest: false,
      TotalPoints: 0,
      TotalAmount: 0,
      MenuItems: [],
    };
  }

  if (action.type === "return_state") {
    return state;
  }

  return state;
}

export function orderOrgCodeDispatch(data) {
  return {
    type: "order_orgCode",
    payload: {
      data,
    },
  };
}

export function orderResidentIdDispatch(data) {
  return {
    type: "order_residentId",
    payload: {
      data,
    },
  };
}

export function orderResNameDispatch(data) {
  return {
    type: "order_resName",
    payload: {
      data,
    },
  };
}

export function orderDateDispatch(data) {
  return {
    type: "order_orderDate",
    payload: {
      data,
    },
  };
}

export function orderTimeDispatch(data) {
  return {
    type: "order_orderTime",
    payload: {
      data,
    },
  };
}

export function orderChargeTypeDispatch(data) {
  return {
    type: "order_chargeType",
    payload: {
      data,
    },
  };
}

export function orderDeliveryDateDispatch(data) {
  return {
    type: "order_deliveryDate",
    payload: {
      data,
    },
  };
}

export function orderDeliveryTimeDispatch(data) {
  return {
    type: "order_deliveryTime",
    payload: {
      data,
    },
  };
}

export function orderDeliveryTypeDispatch(data) {
  return {
    type: "order_deliveryType",
    payload: {
      data,
    },
  };
}

export function orderMealTypeDispatch(data) {
  return {
    type: "order_mealType",
    payload: {
      data,
    },
  };
}

export function orderMenuItemsDispatch(data) {
  return {
    type: "order_menuItems",
    payload: {
      data,
    },
  };
}
export function orderMenuItemsAllChangeDispatch(data) {
  return {
    type: "order_menuItemsAllChange",
    payload: {
      data,
    },
  };
}

export function orderTotalPointsDispatch(data) {
  return {
    type: "order_totalPoints",
    payload: {
      data,
    },
  };
}

export function orderTotalAmountDispatch(data) {
  return {
    type: "order_totalAmount",
    payload: {
      data,
    },
  };
}

export function orderTotalAmountDecDispatch(data) {
  return {
    type: "order_totalAmountDec",
    payload: {
      data,
    },
  };
}

export function orderTotalPointsDecDispatch(data) {
  return {
    type: "order_totalPointsDec",
    payload: {
      data,
    },
  };
}

export function orderTotalAmountInitialDispatch(data) {
  return {
    type: "order_totalAmountsInitial",
    payload: {
      data,
    },
  };
}

export function orderTotalPointsInitialDispatch(data) {
  return {
    type: "order_totalPointsInitial",
    payload: {
      data,
    },
  };
}



export function orderStateLocalStorage() {
  let orderData = JSON.stringify(orderReducer());
  sessionStorage.setItem("order", orderData);
}

export function getOrder() {
  const orderLocalData = sessionStorage.getItem("order");
  return JSON.parse(orderLocalData ? orderLocalData : null);
}

export function orderLogout() {
  sessionStorage.removeItem("order");
  return {
    type: "logout",
  };
}
