import { useSelector } from "react-redux";
import { Navigate, NavLink, Outlet } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const userLogin = useSelector((state) => state.user);
  const chooseTypeSession = JSON.parse(
    sessionStorage.getItem("virtualDinning")
  );

  if (userLogin?.isLoggedIn && chooseTypeSession) {
    return <Navigate to="/del" />;
  }
  return children;
};

export default ProtectedRoute;
