import styled from "styled-components";
import { device } from "../../themes/breakpoints";
export const DelWrapper = styled.div`
  text-align: center;
  height: 100%;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  span.error-type {
    display: inline-block;
    text-align: left;
    color: red;
    margin-top: 10px;
    font-size: 13px;
  }
  form {
    text-align: left !important;
    padding: 0 43px;
    padding-top: 37px;
  }
  .container {
    display: block;
    position: relative;
    margin-bottom: 21px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:hover input ~ .checkmark {
      background-color: #f0f0f0;
    }
    & input:checked ~ .checkmark {
      background-color: #4d7443;
    }
    & input:checked ~ .checkmark:after {
      display: block;
    }
    & .checkmark:after {
      left: 9px;
      top: 4px;
      width: 7px;
      height: 12px;
      border: solid white;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @media ${device.mobileM} {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 7px;
      }
    }

    .type {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 12px;
      color: #484545;
      @media ${device.mobileM} {
        font-size: 15px;
      }
    }
  }
  .type-container {
    margin-top: 26px;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 27px;
    width: 27px;
    border: 1px solid #4d7443;
    border-radius: 50%;
    @media ${device.mobileM} {
      height: 17px;
      width: 17px;
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .orgName {
    display: inline-block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 19px;
    text-align: center;
    color: #484545;
    padding-top: 37px;
    @media ${device.mobileM} {
      font-size: 18px;
    }
  }

  .delTitle {
    max-width: 247px;
    margin: 0 auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #4d7443;
    padding-top: 37px;
  }
  .type-wrapper {
    text-align: left;
    padding: 20px 20px;
    padding-top: 50px;
  }
  .type-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 19px;
    color: #000000;
    @media ${device.mobileM} {
      font-size: 17px;
    }
  }
  .del-data {
    max-width: 275px;
    width: 100%;
    text-align: left;
    margin: 0 auto;
    margin-top: 34px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 19px;
      color: #000000;

    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #484545;
      margin-top: 8px;
    }
    @media ${device.mobileM} {
      span {
        font-size: 17px;
      }
      p {
        font-size: 15px;
      }
    }
  }
  .del-none {
    display: none;
  }
  .step-btn {
    max-width: 338px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 15px;
    background: #4d7443;
    border-radius: 140px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin: 0 auto;
    margin-top: auto;
    margin-bottom: 25px;
  }

  .type-popUp {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .type-popUp-wrapper {
    padding: 0px 20px;
    margin: 0 auto;
  }

  .type-popUp-text p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-bottom: 30%;
  }

  .type-popUp-btn button {
    width: 50%;
    background: #4d7443;
    border-radius: 140px;
    padding: 12px 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .type-popUp-btn {
    display: flex;
    column-gap: 22px;
  }
`;
