/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Loading from "../Loading/Loading";
import { Navigate, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  userLoginResidentDispatch,
  userLoginVirtualDinningDispatch,
  virtualDinning,
} from "../../redux/user/userSlice";
import { LoginWrapper } from "./LoginForm.styled";

// For form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchema from "./validation";
import {
  orderOrgCodeDispatch,
  orderResidentIdDispatch,
  orderResNameDispatch,
} from "../../redux/order/orderSlice";

export const Login = () => {
  const navigate = useNavigate();

  const [dataUserResident, setDataUserResident] = useState(null);
  const [dataUserVirtualDinning, setDataUserVirtualDinning] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const userLogin = useSelector(selectUser);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (localStorage.getItem("user") && userLogin?.user?.userVirtualDinning) {
  //     localStorage.setItem("user", JSON.stringify(userLogin.user));
  //     // navigate("/del");
  //   }
  // }, [userLogin?.user]);
  async function initFirstLogin(data) {
    const requestOptions = {
      method: "POST",
      headers: { Accept: "*/*", "Content-Type": "application/json" },
      body: JSON.stringify({
        Email: data.Email,
        Password: data.Password,
        Type: "Sultan",
      }),
    };
    try {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_MenuURL_LoginResident,
        requestOptions
      );
      const responseData = await response.json();
      if (responseData.IsResident) {
        const dataResident = { ...responseData, ...data };
        setDataUserResident(dataResident);
        initSecondLogin(dataResident);
        sessionStorage.setItem(
          "loginResident",
          JSON.stringify({ ...responseData })
        );
        return;
      }
      setLoading();
      setErrorMsg(responseData.Message);
    } catch (error) {
      console.log(error);
    }
  }

  async function initSecondLogin(data) {
    if (data) {
      const { OrgCode, Email, ResidentID } = data;
      const requestOptions = {
        method: "POST",
        headers: { Accept: "*/*", "Content-Type": "application/json" },
        body: JSON.stringify({
          Email: process.env.REACT_APP_CSEmail,
          Password: process.env.REACT_APP_CSPassword,
          DeviceID: "SultanGO ",
          IPAddress: "",
          RegID: "",
          Type: "PHONE",
          Location: "VIRTUALDINING",
          OrgCode,
        }),
      };
      try {
        const response = await fetch(
          process.env.REACT_APP_MenuURL_LoginVirtualDining,
          requestOptions
        );
        const responseData = await response.json();
        setDataUserVirtualDinning(responseData);
        dispatch(userLoginVirtualDinningDispatch(responseData));
        dispatch(orderOrgCodeDispatch(OrgCode));
        dispatch(orderResidentIdDispatch(ResidentID));
        dispatch(orderResNameDispatch(Email));
        dispatch(virtualDinning(responseData));
        dispatch(userLoginResidentDispatch(data));
        sessionStorage.setItem("user", JSON.stringify({ isLoggedIn: true }));
        sessionStorage.setItem(
          "virtualDinning",
          JSON.stringify({ ...responseData })
        );
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    await initFirstLogin(data);
  };

  const errorRemove = () => {
    setErrorMsg(null);
  };

  if (loading) {
    return <Loading />;
  }

  return !userLogin.user.loginUser ? (
    <LoginWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="email"
          aria-label=""
          aria-required="true"
          placeholder="Username"
          name="Email"
          {...register("Email")}
          onBlur={errorRemove}
        />
        {errors.Email}
        <input
          type="password"
          aria-label=""
          aria-required="true"
          placeholder="Password"
          name="Password"
          {...register("Password")}
          onBlur={errorRemove}
        />
        <span className="errorMsg">{errorMsg ? errorMsg : null}</span>
        <button type="submit" value="submit" id="button_log">
          Login
        </button>
      </form>
    </LoginWrapper>
  ) : (
    <Navigate to={"/home"} />
  );
};
