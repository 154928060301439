import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { selectUser } from "../../redux/user/userSlice";

const PrivateRoute = ({ redirectPath = "/login", children }) => {
  const userLogin = useSelector(selectUser);
  // console.log('userLogin :', userLogin);
  const userSession = sessionStorage.getItem("user");

  // console.log("asd",userLogin.user.isLoggedIn )
  // let userTr = true 
  // if( === null){
  //   userTr = false
  // }
  
  return (userLogin.user.isLoggedIn || userSession?.isLoggedIn) ? children : <Navigate to={redirectPath} />;
};

export { PrivateRoute };
