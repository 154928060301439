import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { SingleProductWrapper } from "./SingleProduct.styled";
import noImage from "../../assets/img/noImage.png";
import closeLogo from "../../assets/img/close.png";
import { useEffect } from "react";
import {
  orderMenuItemsDispatch,
  orderTotalAmountDispatch,
  orderTotalPointsDispatch,
} from "../../redux/order/orderSlice";

export const SingleProduct = ({
  setShowHeader,
  singleProduct,
  setSingleProductView,
}) => {
  const [modifier, setModifier] = useState(null);

  const navigate = useNavigate();
  const stateData = useSelector((state) => state);
  const item = stateData.user.item ? stateData.user.item : null;
  const menuModifiers = stateData.user.menuModifiers
    ? stateData.user.menuModifiers
    : null;
  const [count, setCount] = useState(1);
  const initialPrice = item.Price;
  const [price, setPrice] = useState(null);
  const initialPoints = item.Points;
  const [points, setPoints] = useState(null);

  const [menuItemModifiers, setMenuItemModifiers] = useState([]);

  const menuItemAbout = {
    MenuItemID: item.MenuItemID,
    MenuItem: item.MenuItem,
    Modifiers: "",
    Quantity: 1,
    Price: Math.floor(initialPrice * 100) / 100,
    Points: Math.floor(initialPoints * 10) / 10,
  };

  const [menuItem, setMenuItem] = useState({ ...menuItemAbout });
  const menuGroupName = singleProduct;
  const dispatch = useDispatch();

  useEffect(() => {
    // setShowHeader(false);
    if (menuModifiers !== null && menuGroupName !== null) {
      const menu = [];
      menuModifiers.filter((item) => {
        if (item.MenuGroupName === menuGroupName.toString()) menu.push(item);
      });
      setModifier(...menu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let strModifiers = menuItemModifiers.join(", ").toString();
    setMenuItem((prevState) => ({
      ...prevState,
      Modifiers: strModifiers,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemModifiers]);

  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const updateModifier = (e) => {
    if (e.target.checked) {
      if (menuItemModifiers.length === 0) {
        setMenuItemModifiers([e.target.value]);
      } else {
        setMenuItemModifiers([...menuItemModifiers, e.target.value]);
      }
    } else {
      let arr = menuItemModifiers.filter((item) => item !== e.target.value);
      setMenuItemModifiers([...arr]);
    }
  };

  const addProduct = () => {
    let quantity = count + 1;
    setCount(quantity);
    setMenuItem((prevState) => ({
      ...prevState,
      Quantity: quantity,
    }));

    let totalPrice = quantity * initialPrice;
    totalPrice = (totalPrice * 100) / 100;
    setPrice(totalPrice);

    let totalPoints = quantity * initialPoints;
    totalPoints = (totalPoints * 100) / 100;
    setPoints(totalPoints);
  };
  const delProduct = () => {
    if (count !== 1) {
      let quantity = count - 1;
      setCount(count - 1);
      setMenuItem((prevState) => ({
        ...prevState,
        Quantity: count - 1,
      }));
      let totalPrice = quantity * initialPrice;
      totalPrice = (totalPrice * 100) / 100;
      setPrice(totalPrice);

      let totalPoints = quantity * initialPoints;
      totalPoints = (totalPoints * 100) / 100;
      setPoints(totalPoints);
    }
  };

  const addOrder = () => {
    dispatch(orderMenuItemsDispatch(menuItem));
    if (price === null) {
      let totalAmount = initialPrice;
      dispatch(orderTotalAmountDispatch(totalAmount));
    } else {
      let totalAmount = price;
      dispatch(orderTotalAmountDispatch(totalAmount));
    }
    if (points === null) {
      let totalPoints = initialPoints;
      dispatch(orderTotalPointsDispatch(totalPoints));
    } else {
      let totalPoints = points;
      dispatch(orderTotalPointsDispatch(totalPoints));
    }
    setSingleProductView(false);
  };

  return (
    <SingleProductWrapper>
      <div className="prd">
        <div className="prd_header">
          <button
            onClick={() => {
              setSingleProductView(false);
            }}
            style={{ background: "none" }}
          >
            <img src={closeLogo} alt="closeLogo" />
          </button>
        </div>
        <div className="prd_body">
          <img
            src={item.Icon ? `data:image/png;base64,${item.Icon}` : noImage}
            alt={item.MenuItem}
          />
          <h3 className="prd_title">{item.MenuItem}</h3>
          <p className="prd_price">
            {item.Points
              ? item.Points + " points " + "($ " + item.Price + ")"
              : "$ " + item.Price + ""}
          </p>
          <p className="prd_desc">{item.Description}</p>
          {modifier && (
            <div className="prd_customize">
              <h3 className="">Customize</h3>
              <div className="prd_customize_select">
                {modifier &&
                  modifier.ModifierList.map((item, index) => {
                    const { ModifierType, Modifiers } = item;
                    const isSelected = selected === index;
                    return (
                      <div className="modifier" key={index}>
                        <div className="modifier_type">
                          <p>{ModifierType}</p>
                          <span
                            onClick={() => toggle(index)}
                            className="modifier_type_btn"
                          >
                            {isSelected ? "-" : "+"}
                          </span>
                        </div>
                        <div className="modifierMod">
                          <div
                            className={`type-container ${isSelected && "show"}`}
                          >
                            {Modifiers.map((item, index) => {
                              const { MenuModifier } = item;
                              return (
                                <label key={index} className="container">
                                  <span className="type">{MenuModifier}</span>
                                  <span>
                                    <input
                                      type="checkbox"
                                      value={MenuModifier}
                                      onChange={(e) => updateModifier(e)}
                                    />
                                    <span className="checkmark"></span>
                                  </span>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <div className="prd_footer">
          <div className="add_prd">
            <button
              className="add_prd_dec"
              aria-label="Decrement value"
              onClick={() => delProduct()}
            >
              -
            </button>
            <span className="add_prd_count">{count}</span>
            <button
              className="add_prd_inc"
              aria-label="Increment value"
              onClick={() => addProduct()}
            >
              +
            </button>
          </div>
          <button className="add_cart" onClick={addOrder}>
            Add <span>{menuItem.Quantity}</span> for{" "}
            {menuItem.Quantity * item.Points
              ? (menuItem.Quantity * Math.floor(item.Points * 100)) / 100 +
                " points " +
                "($ " +
                (menuItem.Quantity * Math.floor(item.Price * 100)) / 100 +
                ")"
              : "$  " +
                (menuItem.Quantity * Math.floor(item.Price * 100)) / 100 +
                ""}
          </button>
        </div>
      </div>
    </SingleProductWrapper>
  );
};
