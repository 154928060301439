import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { GlobalStyle } from "./themes/Global.styled";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <GlobalStyle />
          <App />
        </BrowserRouter>
      </Provider>
  // </StrictMode>
);
