import { combineReducers, createStore, applyMiddleware } from "redux";
import { initialUser, userReducer } from "./user/userSlice";
import { deliverReducer, initialDeliver } from "./deliver/deliverSlice";
import thunk from "redux-thunk";
import { initialOrder, orderReducer } from "./order/orderSlice";

const store = createStore(
  combineReducers({
    user: userReducer,
    deliver: deliverReducer,
    order: orderReducer,
  }),
  {
    user: initialUser,
    order: initialOrder,
    deliver: initialDeliver,
  },
  applyMiddleware(thunk)
);

export default store;
