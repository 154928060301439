import React from "react";
import { Outlet } from "react-router";
import { Footer } from "../Footer";
import { Header } from "../Header";

export const Layout = ({
  showHeader,
  showBugIcon,
  orderView,
  setOrderView,
}) => {
  return (
    <div className="layout">
      <Header
        showBugIcon={showBugIcon}
        showHeader={showHeader}
        setOrderView={setOrderView}
        orderView={orderView}
      />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
