import styled from "styled-components";

export const LabelWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  max-width: 283px;
  width: 100%;
  height: 43px;
  border: 1px solid #acaab3;
  border-radius: 4px;
  align-items: center;
  margin: 0 auto;
  margin-top: 44px;
  margin-bottom: 44px;
  padding: 0px 30px;
  input {
    width: 100%;
    background: none !important;
  }
  svg {
    display: block;
  }
`;
export const SetDataWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  z-index: 2;
  .seData-close {
    position: absolute;
    background: none;
    border: none;
    top: 16px;
    right: 21px;
  }
  h3 {
    padding-top: 56px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
  }
  .setData-days {
    padding-top: 48px;
    padding-bottom: 104px;
    button {
      text-align: left;
      background: none;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 13px 32px;
      gap: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #000000;
      &:hover {
        font-weight: 600;
        background: #d3e9dd;
      }
    }
  }
`;
export const DataWrapper = styled.div``;
